
import './App.css';
import { Route} from 'react-router-dom';
import Landing from './components/Landing';
import About from './components/About';



function App() {
  
  return (
    <div className="App">
      
      <Route exact path="/" component={Landing} />
      <Route exact path="/about" component={About} />
    </div>
    
  );
}

export default App;
