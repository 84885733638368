/* eslint-disable */

import React from "react";
import "./footer.css";
import iitkLogo from "../../../assets/img/iitk-logo-with-text.png";
import crubnLogo from "../../../assets/img/crubnLogo.png";




const Footer = () => {


  return (
    <div className="footer">
      <div className="footer-left">
       <img alt="" src={iitkLogo} className="d-inline-block align-top logo" />
        
        
      </div>
      <div className="footer-right">
      <img alt="" src={crubnLogo} className="d-inline-block align-top logo" />
        <p></p>
      </div>
    </div>
  );
};

export default Footer;
