/* eslint-disable */

import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from "axios";

const Chart = (props) => {

  // console.log(props)
  var actualDataArray = [];
  var predictedDataArray = [];
  const [actualPlotData, setActualPlotData] = useState([]);
  const [predictedPlotData, setPredictedPlotData] = useState([]);
  const [maxY, setMaxY] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [actualDataUpto, setActualDataUpto] = useState(null);
  const [modelComputedOn, setModelComputedOn] = useState(null);
  useEffect(() => {
    // console.log("USE-EFFECT called")
    axios({
      method: "get",
      url: props.url,
      headers: {},
    })
      .then(async (response) => {
        console.log('response data', response.data.data)
        if (response.data.status == "success" && response.data.data) {
          const plotDataArray = response.data.data.plotData.data;
          for (let index = 0; index < plotDataArray.length - 1; index++) {
            const element = plotDataArray[index];
            if ((parseInt(element.date.split("/")[0]) >= parseInt("1") && parseInt(element.date.split("/")[2]) >= parseInt("21"))) {
              actualDataArray.push({
                x: plotDataArray[index + 1].date,
                y: (!element.actualData) ? "" : parseInt(element.actualData)
              })
              predictedDataArray.push({
                x: plotDataArray[index + 1].date,
                y: (!element.predictedData) ? "" : parseInt(element.predictedData)
              })
            }
          }

          console.log("actualDataArray", actualDataArray)
          console.log("predictedDataArray", predictedDataArray)

          setLastUpdated(response.data.data.plotData.lastUpdated);
          setActualDataUpto(response.data.data.plotData.actualDataUpto);
          setModelComputedOn(response.data.data.plotData.modelComputedOn);
          const maxValues = response.data.data.plotData.maxValues;
          const maxValue = parseInt(maxValues.actual > maxValues.predicted ? maxValues.actual : maxValues.predicted);
          setPredictedPlotData(predictedDataArray);
          setActualPlotData(actualDataArray);
          const maxValueLength = maxValue.toString().length;
          const firstTwoDigits = parseInt(maxValue.toString().substr(0, 2));
          const maxY = parseInt((firstTwoDigits + 1) + '0'.repeat(maxValueLength - 2));
          setMaxY(maxY + maxY / 20);
        }

      })
  }, [props.url]);
  console.log('hereeee!!! actualData', actualPlotData);
  console.log('hereeee!!! Predictions ', predictedPlotData);
  var series = [
    {
      name: "Actual Data",
      data: actualPlotData
    },
    {
      name: "Model Computed Data",
      data: predictedPlotData
    }
  ]


  var options = {
    chart: {
      height: 350,
      type: 'line',
      width: 500,
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        }

      }
    },
    colors: ['#3d84b8', '#FF8C00'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: `Detected New Infections (7 Day Average)`,
      align: 'center',

      offsetY: 22
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 0
    },
    xaxis: {
      // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      // title: {
      //   text: 'Date'
      // }
      type: "datetime",
      min: new Date('1 Dec 2021').getTime(),
      title: {
        text: 'Date',
        style: {
          color: '#FF0000',
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Infections',
        style: {
          color: '#FF0000',
          fontSize: '14px'
        },
        labels: {
          minWidth: 15,
          maxWidth: 160,
        }
      },
      min: 0,
      max: maxY
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      offsetY: 15,
      offsetX: -5,
      itemMargin: {
        horizontal: 5,
        vertical: 10
      },
      onItemHover: {
        highlightDataSeries: true
      },
    }
  }
  return (
    <div>


      <ApexCharts options={options} series={series} type="line" height={600} />
      {(actualDataUpto) ? <p style={{ textAlign: "center" }} ><i>Actual data updated upto:  {actualDataUpto}</i> </p> : <div />}
      {(modelComputedOn) ? <p style={{ textAlign: "center" }} ><i>Model projections computed on: {modelComputedOn}</i> </p> : <div />}
      <p style={{ textAlign: "center" }} ><i> Accuracy of projections is within &#177; 10%</i> </p>

    </div>


  );
};
export default Chart;
