/* eslint-disable */

import React from 'react';

import Footer from './common/footer/footer';
import TopNav from './common/topnav/topnav';
import './About.css'



const About = () => {
  
  
  
return (
  <div className="">
    <TopNav/>
    <div className="row wrapper min-vh-100 flex-column flex-sm-row" style={{paddingBottom: "11vh", paddingRight:"0px", paddingLeft:"0px", margin:"0px"}}>
        
        <div className="container bg-faded mt-3">
        <h1 style={{fontFamily: 'Raleway'}}>About SUTRA</h1>
        <hr class="hr-1"/>
          <h5 style={{fontFamily: 'Raleway', textAlign: 'justify', textJustify: 'inter-word', margin: 'auto', width: '90%' }}>
          SUTRA is a mathematical model for pandemics, authored by<a href="https://en.wikipedia.org/wiki/Manindra_Agrawal"> M Agrawal (IIT Kanpur),</a> <a href="https://www.ids.nic.in/dcids-med.php#:~:text=Lt%20Gen%20Madhuri%20Kanitkar%2C%20AVSM%2C%20VSM%20has%20assumed%20the%20prestigious,AMC%20on%2027%20Dec%201982."> M Kanitkar (Integrated Defense Staff),</a> <a href="https://www.iith.ac.in/~m_vidyasagar/">and M Vidyasagar (IIT Hyderabad).</a><br/><br/>
          The acronym stands for <strong>Susceptible, Undetected, Tested (positive), and Removed Approach.</strong> There are several novel features in this model. First, whereas previous papers have divided the patient population into Asymptomatic and Infected, SUTRA explicitly accounts for the fact that there would be large numbers of undetected asymptomatic patients.
          Second, the model explicitly takes into account the spatial spread of a pandemic over time, through a parameter called "reach." Third, the model uses numerically stable methods for estimating the values of all the parameters using the daily new infections data series. All data used for modelling is from <a href="https://www.covid19india.org" >www.covid19india.org</a>. 
          <br/>For more details visit: <a href="https://arxiv.org/abs/2101.09158" target="_blank">https://arxiv.org/abs/2101.09158</a>
          <br/>A presentation on the model is available at <a href="https://drive.google.com/file/d/1A5F_ClkYcullyHV9XpeNeBP1JvCcPTiH/view" target="_blank">https://drive.google.com/file/d/1A5F_ClkYcullyHV9XpeNeBP1JvCcPTiH/view</a><br/><br/>
          
          The model is being used to track the progression of the COVID-19 pandemic in India. In this site, simulations for India, several states and districts are being shown. These simulations are updated regularly using new data.<br/><br/>

          This portal is developed by <a href ="https://crubn.com/">CRUBN</a>, a company incubated at IIT Kanpur developing blockchain-based e-governance solutions in India
          </h5>

          </div>
        <Footer/>
    </div>
  </div>
);
};
export default About;
