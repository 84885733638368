/* eslint-disable */

import React from "react";


const TopNav = () => {

  return (
    <nav style={{ backgroundColor: "#1a508b" }} className="navbar navbar-expand-lg navbar-dark " >
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <h4 style={{ float: "left", color: "white", margin: '0' }} >SUTRA</h4><br />
          <p style={{ color: "white", fontSize: "11px", float: "left", margin: '0'}} >COVID India Predictions</p>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">

          </ul>
          <ul className="navbar-nav ">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " aria-current="page" href="/about">About</a>
            </li>
          </ul>

        </div>
      </div>
    </nav>
  );
};

export default TopNav;
